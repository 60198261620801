import imgT1 from '../assets/image/masonry/brainy-mas.webp'
import imgT2 from '../assets/image/masonry/mtr-mas.webp'
import imgT3 from '../assets/image/masonry/universum-mas.webp'
import imgT4 from '../assets/image/masonry/freestep-masonry.jpg'
import imgT5 from '../assets/image/masonry/inspirers-mas.jpg'
import imgT6 from '../assets/image/masonry/dzemo-mas.webp'
import imgT8 from '../assets/image/masonry/kikimora-mas.webp'
import imgT9 from '../assets/image/masonry/db-mas.webp'
import imgT10 from '../assets/image/masonry/wayw-mas.webp'
import imgT11 from '../assets/image/masonry/youknowme-mas.webp'
import imgT12 from '../assets/image/masonry/Bmania-mas4.webp'
import imgT7 from '../assets/image/masonry/Gravity.jpg'
export const projects = [
  {
    brand: `Mtr - landing page`,
    slug: `Mtr-landing page`,
    title: `Mtr - Mentoring Platform.`,
    categories: [`coding`],
    thumbnail: imgT2,
    link: '/mtor-details',
  },
  {
    brand: `Berlinomania`,
    slug: `berlinomania`,
    title: `Tour Guide Service in Berlin.`,
    categories: ['coding', 'ui/UX Design'],
    thumbnail: imgT12,
    link: '/berlinomania-details',
  },
  {
    brand: `Brainy`,
    slug: `brainy`,
    title: `Brainy - Note Taking App.`,
    categories: ['ui/UX Design'],
    thumbnail: imgT1,
    link: '/brainy-details',
  },
  {
    brand: `Inspirers `,
    slug: `freestep`,
    title: `Freestep`,
    categories: [`ui/UX Design`],
    thumbnail: imgT5,
    link: '/inspirers-details',
  },
  {
    brand: `Crypto-app`,
    slug: `cryptoapp`,
    title: `CryptoApp`,
    categories: [`ui/UX Design`],
    thumbnail: imgT3,
    link: '/cryptoapp-details',
  },
  {
    brand: `Freestep`,
    slug: `berlin-calling`,
    title: `Freestep`,
    categories: [`ui/UX Design`],
    thumbnail: imgT4,
    link: '/freestep-details',
  },
  {
    brand: `Dzemo`,
    slug: `dzemo`,
    title: `Dzemo`,
    categories: [`coding`],
    thumbnail: imgT6,
    link: '/dzemo-details',
  },
  {
    brand: `Wave`,
    slug: `wave`,
    title: `App.`,
    categories: [`ui/UX Design`],
    thumbnail: imgT10,
    link: '/wave-details',
  },
  {
    brand: `Kikimora `,
    slug: `manga-girl`,
    title: `Kikimora`,
    categories: [`fashion`],
    thumbnail: imgT8,
    link: '/kikimora-details',
  },
  {
    brand: `You know me well`,
    slug: `art-gallery`,
    title: `You know me well`,
    categories: [`fashion`],
    thumbnail: imgT11,
    link: '/youknowmewell-details',
  },
  {
    brand: `Studio dB`,
    slug: `DB-studio`,
    title: `Studio dB`,
    categories: [`fashion`],
    thumbnail: imgT9,
    link: '/StudiodB-details',
  },
  {
    brand: `BerlinCalling`,
    slug: `BerlinCalling`,
    title: `BerlinCalling`,
    categories: [`ui/UX Design`],
    thumbnail: imgT7,
    link: '/berlincalling-details',
  },
]
